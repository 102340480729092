import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import Footer from "Components/Footer";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router";
import AuthAPIs from "../../APIs/auth";
import Loader from "Components/Loader";
import UserTable from "Components/Table";
import SampleDataTable from "Components/DataTables";
import { formatDate } from "Helper/Converters";
import { Link } from "react-router-dom";

const ManageUser = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    const users = await AuthAPIs.getAllUsers();
    console.log("users", users);
    if (users) {
      setUsers(
        users.data.data?.sort((a, b) => {
          if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
            return -1;
          }
          if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    }
    setIsLoading(false);
  };

  const columns = [
    {
      accessor: "username",
      Header: "Username", //todo: we have to change it to firstName from backend and frontend
    },
    { accessor: "email", Header: "Email" },
    {
      accessor: "department",
      Header: "Department",
      Cell: ({ row }) => <p>{row?.original?.department?.name}</p>,
    },
    { accessor: "city", Header: "City" },
    { accessor: "phoneNumber", Header: "Phone No." },
    { accessor: "emergencyPhoneNumber", Header: "Emergency Phone Number" },
    {
      accessor: "dob",
      Header: "DOB",
      Cell: ({ row }) => (
        <p>{row?.original?.dob ? formatDate(row.original.dob) : ""}</p>
      ),
    },
    {
      accessor: "id",
      Header: "Action",
      Cell: ({ row }) => (
        <Link
          className="link"
          to={`/dashboard/user-setup/edit/${row.original.id}`}
          rel="noopener noreferrer"
        >
          Edit
        </Link>
      ),
    },
  ];

  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <section>
        <Heading text={"Manage User"} />
        <Card>
          <Col
            className="column"
            style={{
              maxWidth: "100%",
              marginTop: "28px",
              paddingBottom: "20px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button onClick={() => navigation("/dashboard/user-setup/add")}>
              <AiOutlinePlus />
              Add
            </Button>
          </Col>
          <SampleDataTable
            columns={columns}
            data={users}
            noDataMsg={"No Users Available"}
          />
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default ManageUser;
