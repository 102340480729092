import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const createTicket = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.create, true, data);
};

const search = async (data) => {
  return await API.postMethod(`${ENDPOINT.tickets.search}`, true, data);
};

const searchTicket = async (data) => {
  return await API.postMethod(`${ENDPOINT.tickets.search}`, true, data);
};

const assignReassignTicket = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.assignReassign, true, data);
};

const addTicketLog = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.addLog, true, data);
};

const officeClear = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.officeClear, true, data);
};

const ticketStats = async () => {
  return await API.getMethod(ENDPOINT.tickets.stats, true);
};

const ticketOutstanding = async () => {
  return await API.getMethod(ENDPOINT.tickets.outstandingTickets, true);
};

const closeTickets = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.close, true, data);
};

const ticketLogs = async (data) => {
  return await API.postMethod(`${ENDPOINT.tickets.logs}`, true, data);
};

const ticketFiles = async (data) => {
  return await API.postMethod(`${ENDPOINT.tickets.getFiles}`, true, data);
};

const sendToLocate = async (data) => {
  return await API.postMethod(`${ENDPOINT.tickets.sendToLocate}`, true, data);
};

const sendEmail = async (data) => {
  return await API.postMethod(`${ENDPOINT.tickets.sendEmail}`, true, data);
};

const preCompleteTicket = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.preComplete, true, data);
};

const preCompleteTicketDetail = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.preCompleteDetail, true, data);
};

const deletePreCompleteTicketDetail = async (id) => {
  return await API.deleteMethod(
    `${ENDPOINT.tickets.preCompleteDetail}?ticketId=${id}`,
    true
  );
};

const completeTicket = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.complete, true, data);
};

const uncompleteTicket = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.uncomplete, true, data);
};

const cancelTicket = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.cancel, true, data);
};

const updateTicket = async (id, data) => {
  return await API.patchMethod(`${ENDPOINT.tickets.create}/${id}`, true, data);
};

const officeClearStation = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.officeClearStation, true, data);
};

const getOfficeClearStationCode = async (id) => {
  console.log(
    "DUCK",
    "getOfficeClearStationCode",
    `${ENDPOINT.tickets.getOfficeClearStationCode}/${id}`
  );
  return await API.getMethod(
    `${ENDPOINT.tickets.getOfficeClearStationCode}/${id}`,
    true
  );
};

const getTicketPreCompleteData = async (id) => {
  return await API.getMethod(
    `${ENDPOINT.tickets.getTicketPreComplete}/${id}`,
    true
  );
};

const addLog = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.addLog, true, data);
};

const rescheduleTicket = async (data) => {
  return await API.putMethod(ENDPOINT.tickets.rescheduleTicket, true, data);
};

const revokeTicket = async (data) => {
  return await API.putMethod(ENDPOINT.tickets.revokeTicket, true, data);
};

const nonCompliantReport = async (data) => {
  return await API.postMethod(ENDPOINT.tickets.nonCompliantReport, true, data);
};

const adminDashboardStats = async (startDate,endDate) => {
  return await API.getMethod(`${ENDPOINT.tickets.adminDashboardStats}?startDate=${new Date(startDate).toLocaleDateString()}&endDate=${new Date(endDate).toLocaleDateString()}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createTicket,
  search,
  searchTicket,
  assignReassignTicket,
  officeClear,
  ticketStats,
  closeTickets,
  ticketLogs,
  addTicketLog,
  ticketFiles,
  sendToLocate,
  sendEmail,
  preCompleteTicket,
  preCompleteTicketDetail,
  completeTicket,
  cancelTicket,
  updateTicket,
  uncompleteTicket,
  ticketOutstanding,
  deletePreCompleteTicketDetail,
  officeClearStation,
  getOfficeClearStationCode,
  getTicketPreCompleteData,
  addLog,
  rescheduleTicket,
  revokeTicket,
  nonCompliantReport,
  adminDashboardStats
};
