import React, { useState, useEffect, memo } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { format, startOfWeek, endOfWeek } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { BiSearch } from "react-icons/bi";
import AddLogModal from "./components/AddLogModal";

import HourLogAPIs from "../../APIs/hour-log";
import { formatDate } from "Helper/Converters";
import Heading from "Components/Heading";
import CustomDatePicker from "Components/CustomDatePicker";

const HourLogs = memo(() => {
  const [startDate, setStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [endDate, setEndDate] = useState(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [logs, setLogs] = useState([]);
  const [addLogModalShow, setAddLogModalShow] = useState(false);
  const [logDate, setLogDate] = useState(format(new Date(), "yyyy-MM-dd")); // Default to current date

  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');

  const fetchHourLogs = async (fromDate, toDate, userId) => {
    console.log('frommmm', fromDate)
    console.log('toooooo', toDate)
    setLoading(true);
    try {
      const response = await HourLogAPIs.getHourLog(fromDate, toDate, userId);
      if (response.data) {
        // Assuming the response has a data property; adjust according to your API response structure
        setLogs(response.data.data);
      } else {
        setLogs([]);
      }
    } catch (error) {
      console.error("Error fetching hour logs:", error);
      setLogs([]);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModalWithDate = (date) => {
    setLogDate(new Date(date).setHours(19));
    setAddLogModalShow(true);
  };
  const fetchData=()=>{
    const from = format(startDate, "yyyy-MM-dd");
    const to = format(endDate, "yyyy-MM-dd");
    const user = JSON.parse(localStorage.getItem('user'))
    fetchHourLogs(from, to, user?.id);
    setUserId(user?.id);
  }
  useEffect(() => {
    fetchData()
  }, [startDate, endDate]);
  const handleHourLogModalClose =() => {
    fetchData()
    setAddLogModalShow(false)
  }
  return (
    <>
      <section style={{ overflow: "visible" }}>
        <Heading text={"Hours Log"} />
        <Card>
          <Form className="dashboard-form mb-4">
            <Row>
              <Col className="mb-4 column">
                <Button
                  style={{ minHeight: "44px" }}
                  onClick={() => {
                    setLogDate(new Date());
                    setAddLogModalShow(true);
                  }}
                >
                  Add Log
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-group">
                  <Form.Label>Date From</Form.Label>
                  <CustomDatePicker
                    selected={startDate}
                    onSelect={(date) => setStartDate(date)}
                    dateFormat="yyyy-MM-dd"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group">
                  <Form.Label>Date To</Form.Label>
                  <CustomDatePicker
                    selected={endDate}
                    onSelect={(date) => setEndDate(date)}
                    dateFormat="yyyy-MM-dd"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          {!loading ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => (
                  <tr
                    key={index}
                    onClick={() => handleOpenModalWithDate(log.date)}
                  >
                    <td style={{ cursor: "pointer" }}>
                      {formatDate(new Date(log.date).setHours(19))}
                    </td>
                    <td>{log.totalHours ? log.totalHours : 0}/hr</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data available!</p>
          )}
          <AddLogModal
            show={addLogModalShow}
            handleClose={handleHourLogModalClose}
            logDate={logDate}
            setLogDate={setLogDate}
          />
        </Card>
      </section>
    </>
  );
});

export default HourLogs;
