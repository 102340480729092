import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import { TbClipboardCheck } from "react-icons/tb";
import Footer from "Components/Footer";
import Loader from "Components/Loader";
import { useSelector } from "react-redux";
import TicketAPIs from "../../APIs/ticket";
import { toast } from "react-toastify";
import SampleDataTable from "Components/DataTables";
import SearchForm from "Components/SearchForm";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import AuthAPIs from "../../APIs/auth";
import { formatDate } from "Helper/Converters";
import { MdClose } from "react-icons/md";
import { wrapLastTextInBracketsWithStrong } from "Utils/utils";

const CloseTickets = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [user, setUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [unAssignedTicket, setUnAssignedTicket] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const [searchedMasterData, setSearchedMasterData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [stats, setStats] = useState();
  const [successMsg, setSuccessMsg] = useState("");

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({ ...checkedItems, [name]: checked });
  };

  const handleSelectionChange = (selectedIds) => {
    console.log("Selected IDs:", selectedIds);
    setSelectedIds(selectedIds);
  };

  const getTicketStats = async () => {
    setIsLoading(true);
    const res = await TicketAPIs.ticketStats();
    if (res) {
      setStats(res.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    getTicketStats();
  }, []);

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticketNo}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
          className={`badge ${row.original?.cancelDate && "custom-badge"} ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticketNo}
          {row.original?.cancelDate && <span className="cancel-btn"></span>}
        </a>
      ),
    },
    {
      accessor: "location",
      Header: "Location",
      Cell: ({ row }) =>
        wrapLastTextInBracketsWithStrong(row.original.location),
    },
    { accessor: "entered", Header: "Entered" },
    // {
    //   accessor: "assigntoo",
    //   Header: "Assigned To",
    //   Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
    // },
    // {
    //   accessor: "assign",
    //   Header: "Assigned Date",
    // },
    // {
    //   accessor: "reassinged",
    //   Header: "Reassigned To",
    //   Cell: ({ row }) => <p>{row.original.reassigned?.firstName}</p>,
    // },
    // { accessor: "reassingndate", Header: "Reassigned Date" },
    {
      accessor: "preCompleteBy",
      Header: "Pre Completed By",
      Cell: ({ row }) => <p>{row.original.preCompleteBy?.firstName} {row.original.preCompleteBy?.lastName}</p>,
    },
    { accessor: "preCompletedDate", Header: "Pre Completed Date" },
    { accessor: "due", Header: "Due" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    // { accessor: "segmet", Header: "Segment" },
  ];

  const officeClearOrClose = async (type) => {
    console.log(
      "DUCK",
      "closeTicket:officeClearOrClose:searchedData",
      searchedData
    );
    if (searchedData.length > 0) {
      if (!selectedIds[0]) {
        toast.error("No Tickets Selected");
        return;
      }

      if (!user?.id) {
        toast.error("No User Selected");
        return;
      }

      console.log(
        "DUCK",
        "closeTicket:officeClearOrClose:assignUserID",
        user?.id
      );
      setIsLoading(true);
      if (type === "office_clear") {
        const res = await TicketAPIs.officeClear({
          ticketId: selectedIds,
          userId: user?.id,
        });
        if (res) {
          toast.success("Office Clear Successfully");
        }
      } else if (type === "close") {
        const close = await TicketAPIs.closeTickets({
          ticketId: selectedIds,
          userId: user?.id,
        });
        if (close) {
          // toast.success("Tickets Closed Successfully");
          const selectedTicketsNumbers = searchedData
            .filter((item) => selectedIds.indexOf(item.id) > -1)
            .map((item) => item.ticketNo);
          setSuccessMsg(
            `Following Tickets ${selectedTicketsNumbers.join(
              ","
            )} has been closed successfully.`
          );
          getTickets();
        }
      }
      setSelectedIds([]);
      //setSearchedData([]);
      setIsLoading(false);
    }
  };

  const getTickets = async () => {
    const tickets = await TicketAPIs.search({
      preCompletedDate: "notNull",
      completedDate: null,
      isOfficeClear: false,
      removeOr: true,
    });
    if (tickets) {
      setSearchedData(tickets?.data?.data || []);
      setSearchedMasterData(tickets?.data?.data || []);
    }
    setIsLoading(false);
  };

  const getUsers = async () => {
    const users = await AuthAPIs.getAllUsers();
    const customArr = [];
    if (users) {
      users.data?.data?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.firstName} ${item?.lastName}`,
        };
        if (item?.id !== userId) customArr.push(data);
      });
      customArr.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setUsers([{ value: "", label: "Select" }, ...customArr]);
    }
  };

  useEffect(() => {
    getTickets();
    getUsers();
  }, []);

  useEffect(() => {
    if (selectedUser?.value) {
      const fullName = selectedUser.label;
      setSearchedData(
          searchedMasterData.filter((item) => {
            const assignToFullName = `${item.assigntoo?.firstName} ${item.assigntoo?.lastName}`;
            const reassignFullName = `${item.reassinged?.firstName} ${item.reassinged?.lastName}`;
            return (
                assignToFullName === fullName ||
                reassignFullName === fullName
            );
          })
      );
    } else {
      setSearchedData(JSON.parse(JSON.stringify(searchedMasterData)));
    }
  }, [selectedUser]);

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Close Tickets"} />
          {successMsg && (
            <div className="successMsg">
              <strong style={{ margin: 0, color: "rgb(0,120,0)" }}>
                {successMsg}
              </strong>
              <MdClose
                size={20}
                color={"gray"}
                onClick={() => setSuccessMsg("")}
                style={{ position: "absolute", top: 10, right: 10 }}
              />
            </div>
          )}
          <Card>
            {/* <SearchForm
              setSearchedData={setSearchedData}
              setIsLoading={setIsLoading}
              filters={{
                preCompletedDate: "notNull",
                completedDate: null,
                isOfficeClear: false,
              }}
              loadDataOnMount={true}
              hiddenElements={[
                "typeOfWork",
                "ticketNo",
                "digAddress",
                "digNearestIntersection",
                "contractorName",
                "ticketType",
                "callerName",
                "stationCodes",
                "dateType",
                "fromDate",
                "toDate",
                "assignTo",
                "reAssignTo",
                "cancelDate"
              ]}
            /> */}
            <Row>
              <Col className="column">
                <Form.Group className="form-group">
                  <Form.Label>Users</Form.Label>
                  <Select
                    options={users}
                    styles={customStyles}
                    defaultValue={users[0]}
                    onChange={(selectedOption) => {
                      setSelectedUser(selectedOption);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col md={12}>
              <Button
                className="me-3 mb-3"
                onClick={() => {
                  officeClearOrClose("close");
                }}
              >
                <TbClipboardCheck />
                Close Tickets
              </Button>
              {/* <Button
                onClick={() => {
                  officeClearOrClose("office_clear");
                }}
              >
                <TbClipboardCheck />
                Office Clear
              </Button> */}
            </Col>
            <SampleDataTable
              columns={columns}
              data={searchedData}
              onSelectionChange={handleSelectionChange}
            />
            <Footer />
          </Card>
        </section>
      )}
    </>
  );
};

export default CloseTickets;
