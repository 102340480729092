import Heading from "Components/Heading";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { BiBookAdd, BiSearch } from "react-icons/bi";
import { MdDateRange } from "react-icons/md";
import Footer from "Components/Footer";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx-js-style";

import AuthAPIs from "../../APIs/auth";
import HourLogAPIs from "../../APIs/hour-log";
import PayrollLogModal from "Pages/HoursLog/components/PayrollLogModal";
import { format } from "date-fns";
import PerTicketReportModal from "Pages/HoursLog/components/PerTicketReportModal";
import { formatDate } from "Helper/Converters";
import CustomDatePicker from "Components/CustomDatePicker";

const Payroll = () => {
  const { userId } = useSelector((state) => state.auth);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [hourLogs, setHourLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [
    approveDisapproveHourLogModalShow,
    setApproveDisapproveHourLogModalShow,
  ] = useState(false);
  const [logDate, setLogDate] = useState(format(new Date(), "yyyy-MM-dd")); // Default to current date

  const [perTicketReportModalShow, setPerTicketReportModalShow] =
    useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px",
    }),
  };

  // Convert Date object to desired format
  const customFormatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  /**
   * Get All Users.
   */
  const getUsers = useCallback(async () => {
    const usersResponse = await AuthAPIs.getAllUsers();
    const customArr = [{ value: "", label: "Select" }];
    if (usersResponse) {
      usersResponse.data.data.forEach((item) => {
          customArr.push({ value: item.id, label: item.firstName });
      });
      customArr.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      setUsers([{ value: "", label: "Select" }, ...customArr]);
    }
  }, [userId]);

  useEffect(() => {
    if (users.length === 0) getUsers();
  }, [getUsers, users.length]);

  const data = [
    {
      Name: "John Doe",
      Age: 30,
      Occupation: "Developer",
      Country: "USA",
      Salary: "$1000",
    },
    {
      Name: "Jane Doe",
      Age: 25,
      Occupation: "Designer",
      Country: "UK",
      Salary: "$1500",
    },
    // Add more rows as needed
  ];

  const exportToExcel = (data, fileName) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Export the workbook
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const createHourLogReport = async () => {
    console.log(
      `selectedUser: ${selectedUser?.value} startDate: ${startDate}   endDate:${endDate}`
    );
    if (!selectedUser?.value || !startDate || !endDate) {
      alert("Please select a user and both dates.");
      return;
    }

    setIsLoading(true);

    // Format dates
    const formattedStartDate = customFormatDate(startDate);
    const formattedEndDate = customFormatDate(endDate);

    // Dummy API call structure, replace with your actual API call
    try {
      const response = await HourLogAPIs.getPayrollReport(
        selectedUser.value,
        formattedEndDate,
        formattedStartDate
      );

      console.log(formattedStartDate);
      console.log(formattedEndDate);
      console.log("thisssss", response.data.data);
      if (response.data?.success) {
        setHourLogs(
          response.data.data.map((item) => {
            return {
              ...item,
              totalHours: Number(Number(item.totalHours).toFixed(1)),
              approvedHours: Number(Number(item.approvedHours).toFixed(1)),
            };
          })
        );
      } else {
        alert(response.message || "Failed to fetch hour logs.");
      }
    } catch (error) {
      console.error("Failed to fetch hour logs:", error);
      alert("An error occurred while fetching hour logs.");
    } finally {
      setIsLoading(false);
    }
  };

  const getHoursLogTicketReport = async () => {
    console.log(
      `selectedUser: ${selectedUser?.value} startDate: ${startDate}   endDate:${endDate}`
    );
    if (!selectedUser?.value || !startDate || !endDate) {
      alert("Please select a user and both dates.");
      return;
    }

    setHourLogs([]);
    setIsLoading(true);

    // Format dates
    const formattedStartDate = customFormatDate(startDate);
    const formattedEndDate = customFormatDate(endDate);

    // Dummy API call structure, replace with your actual API call
    try {
      const response = await HourLogAPIs.getHoursLogTicketReport(
        selectedUser.value,
        formattedEndDate,
        formattedStartDate,
        true
      );

      console.log(formattedStartDate);
      console.log(formattedEndDate);
      console.log("getHoursLogTicketReport response ", response.data?.data);
      if (response.data?.data?.length > 0) {
        const HEADINGS = [
          {
            v: "Entered Date",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Ticket Number",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Station Code",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Type of Work",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Brampton Storm Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "CATV Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Electrical Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Gas Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Hydro Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Other Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Peel Storm Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Sanitary Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Sewer Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Street Light Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Telecom Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Traffic Light Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Water Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Zum Unit",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Per Ticket",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "AHE Ticket",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
        ];
        const data = response.data.data.map((item) => [
          { v: new Date(formatDate(item.createdAt)).toLocaleDateString() },
          { v: item.ticketNo },
          { v: item.stationCodes?.map((it) => it?.name).join(" ") },
          { v: item.typeOfWork },
          {
            v:
              item.ticketPreComplete?.bramptonStormUnit &&
                !["NaN"].includes(item.ticketPreComplete?.bramptonStormUnit)
                ? item.ticketPreComplete?.bramptonStormUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.catvUnit &&
                !["NaN"].includes(item.ticketPreComplete?.catvUnit)
                ? item.ticketPreComplete?.catvUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.electricalUnit &&
                !["NaN"].includes(item.ticketPreComplete?.electricalUnit)
                ? item.ticketPreComplete?.electricalUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.gasUnit &&
                !["NaN"].includes(item.ticketPreComplete?.gasUnit)
                ? item.ticketPreComplete?.gasUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.hydroUnit &&
                !["NaN"].includes(item.ticketPreComplete?.hydroUnit)
                ? item.ticketPreComplete?.hydroUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.otherUnit &&
                !["NaN"].includes(item.ticketPreComplete?.otherUnit)
                ? item.ticketPreComplete?.otherUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.peelStormUnit &&
                !["NaN"].includes(item.ticketPreComplete?.peelStormUnit)
                ? item.ticketPreComplete?.peelStormUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.sanitaryUnit &&
                !["NaN"].includes(item.ticketPreComplete?.sanitaryUnit)
                ? item.ticketPreComplete?.sanitaryUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.sewerUnit &&
                !["NaN"].includes(item.ticketPreComplete?.sewerUnit)
                ? item.ticketPreComplete?.sewerUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.streetLightUnit &&
                !["NaN"].includes(item.ticketPreComplete?.streetLightUnit)
                ? item.ticketPreComplete?.streetLightUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.telecomUnit &&
                !["NaN"].includes(item.ticketPreComplete?.telecomUnit)
                ? item.ticketPreComplete?.telecomUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.trafficLightUnit &&
                !["NaN"].includes(item.ticketPreComplete?.trafficLightUnit)
                ? item.ticketPreComplete?.trafficLightUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.waterUnit &&
                !["NaN"].includes(item.ticketPreComplete?.waterUnit)
                ? item.ticketPreComplete?.waterUnit
                : 0,
          },
          {
            v:
              item.ticketPreComplete?.zumUnit &&
                !["NaN"].includes(item.ticketPreComplete?.zumUnit)
                ? item.ticketPreComplete?.zumUnit
                : 0,
          },
          { v: item.logType == "PER_TICKET" ? "Yes" : "No" },
          { v: item.logType == "AHE_TICKET" ? "Yes" : "No" },
        ]);

        exportToExcel([HEADINGS, ...data], `${selectedUser?.label} Ticket Report ${new Date(startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) + "-" + new Date(endDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`);
      } else {
        alert("No Data Exists!");
      }
      //if (response.success) {
      // setHourLogs(response.data.data);
      // } else {
      //   //alert(response.message || "Failed to fetch hour logs.");
      // }
    } catch (error) {
      console.error("Failed to fetch hour logs ticket report:", error);
      // alert("An error occurred while fetching hour logs ticket report.");
    } finally {
      setIsLoading(false);
    }
  };

  const getHoursLogPreTicketReport = async () => {
    // console.log(
    //   `selectedUser: ${selectedUser?.value} startDate: ${startDate}   endDate:${endDate}`
    // );
    // if (!selectedUser?.value || !startDate || !endDate) {
    //   alert("Please select a user and both dates.");
    //   return;
    // }

    setHourLogs([]);
    setIsLoading(true);

    // Format dates
    const formattedStartDate = customFormatDate(startDate);
    const formattedEndDate = customFormatDate(endDate);

    // Dummy API call structure, replace with your actual API call
    try {
      // const response = await HourLogAPIs.getHoursLogPerTicketReport(
      //   selectedUser.value,
      //   formattedEndDate,
      //   formattedStartDate
      // );

      // console.log(formattedStartDate);
      // console.log(formattedEndDate);
      // console.log("getHoursLogPerTicketReport response ", response.data?.data);
      // if (response.data?.data?.length > 0) {
      // const data = response.data.data.map(item => ({
      //   ["Station Code"]: item.stationCodes?.map(it => it?.name).join(' '),
      //   ["Total Standard Segments"]: 0,
      //   ["Total Additional Segments"]: 0,
      // }))
      const HEADINGS = [
        {
          v: "Station Code",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Total Standard Segments",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Total Additional Segments",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
      ];
      const data = new Array(3)
        .fill(1)
        .map((item) => [{ v: "" }, { v: 0 }, { v: 0 }]);
      console.log("dataaaaaa", data);
      exportToExcel([HEADINGS, ...data], "Create Pre Tickets Report");
      // } else {
      //   alert("No Data Exists!");
      // }
      //if (response.success) {
      // setHourLogs(response.data.data);
      // } else {
      //   //alert(response.message || "Failed to fetch hour logs.");
      // }
    } catch (error) {
      console.error("Failed to fetch hour logs per ticket report:", error);
      // alert("An error occurred while fetching hour logs per ticket report.");
    } finally {
      setIsLoading(false);
    }
  };

  const showTotal = useMemo(() => {
    const TotalInitial = {
      totalHours: 0.0,
      totalHourLogCount: 0.0,
      perTicketCount: 0,
      aheTicketCount: 0,
      reimbursementCount: 0,
      approvedHours: 0.0,
    };
    const total = hourLogs.reduce((total, item) => {
      console.log("itemmm", item);
      return {
        totalHours: total.totalHours + item.totalHours,
        totalHourLogCount: total.totalHourLogCount + item.hourLogCount,
        perTicketCount: total.perTicketCount + item.perTicketCount,
        aheTicketCount: total.aheTicketCount + item.aheTicketCount,
        reimbursementCount: total.reimbursementCount + item.reimbursementCount,
        approvedHours: total.approvedHours + item.approvedHours,
      };
    }, TotalInitial);
    return (
      <tr>
        <td>Total</td>
        <td>{Number(total.totalHours).toFixed(1)}</td>
        <td>{total.totalHourLogCount}</td>
        <td>{total.perTicketCount}</td>
        <td>{total.aheTicketCount}</td>
        <td>0.0</td>
        <td>0.0</td>
        <td>{total.reimbursementCount}</td>
        <td>{Number(total.approvedHours).toFixed(1)}</td>
      </tr>
    );
  }, [hourLogs]);
  const handleClosePayrollLogModal = () => {
    createHourLogReport()
   setApproveDisapproveHourLogModalShow(false)
  }
  return (
    <>
      <section>
        <Heading text={"Payroll Audit"} />

        <Card>
          <Form className="dashboard-form mb-4">
            <Row>
              <Col className="column">
                <Form.Group className="form-group">
                  <Form.Label>Users</Form.Label>
                  <Select
                    options={users}
                    styles={{
                      ...customStyles,
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust zIndex value as needed
                    }}
                    menuPortalTarget={document.body}
                    defaultValue={users[0]}
                    onChange={(selectedOption) => {
                      setSelectedUser(selectedOption);
                    }}
                  />
                </Form.Group>
              </Col>

              <Row className="pe-0">
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Date From</Form.Label>
                    <div className="datepicker_box">
                      <CustomDatePicker
                        selected={endDate}
                        onSelect={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd" // Adjust the date format as needed
                        popperPlacement="right-start"
                      />
                      <span>
                        <MdDateRange />
                      </span>
                    </div>
                  </Form.Group>
                </Col>
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Date To</Form.Label>
                    <div className="datepicker_box">
                      <CustomDatePicker
                        selected={startDate}
                        onSelect={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd" // Adjust the date format as needed
                        popperPlacement="right-start"
                      />
                      <span>
                        <MdDateRange />
                      </span>
                    </div>
                  </Form.Group>
                </Col>
                <Col
                  className="mb-4 column"
                  style={{ maxWidth: "120px", padding: "0" }}
                >
                  <Button
                    style={{ minHeight: "44px", marginTop: "30px" }}
                    onClick={createHourLogReport}
                  >
                    <BiSearch />
                    Search
                  </Button>
                </Col>
                <Col md={12}>
                  <Button
                    className="me-3"
                    onClick={() => {
                      if (!selectedUser?.value || !startDate || !endDate) {
                        alert("Please select a user and both dates.");
                        return;
                      }
                      if (!(hourLogs.length > 0)) {
                        alert("No Data Exists!");
                        return;
                      }
                      const HEADINGS = [
                        {
                          v: "Entered Date",
                          t: "s",
                          s: {
                            font: {
                              bold: true,
                              sz: 16,
                              color: { rgb: "0068af" },
                            },
                          },
                        },
                        {
                          v: "Approved Hours",
                          t: "s",
                          s: {
                            font: {
                              bold: true,
                              sz: 16,
                              color: { rgb: "0068af" },
                            },
                          },
                        },
                        {
                          v: "OT Hours",
                          t: "s",
                          s: {
                            font: {
                              bold: true,
                              sz: 16,
                              color: { rgb: "0068af" },
                            },
                          },
                        },
                        {
                          v: "STAT Hours",
                          t: "s",
                          s: {
                            font: {
                              bold: true,
                              sz: 16,
                              color: { rgb: "0068af" },
                            },
                          },
                        },
                        {
                          v: "AHE Hours",
                          t: "s",
                          s: {
                            font: {
                              bold: true,
                              sz: 16,
                              color: { rgb: "0068af" },
                            },
                          },
                        },
                        {
                          v: "AHE Count",
                          t: "s",
                          s: {
                            font: {
                              bold: true,
                              sz: 16,
                              color: { rgb: "0068af" },
                            },
                          },
                        },
                        {
                          v: "Per-Ticket Count",
                          t: "s",
                          s: {
                            font: {
                              bold: true,
                              sz: 16,
                              color: { rgb: "0068af" },
                            },
                          },
                        },
                        {
                          v: "Hourlog Ticket Count",
                          t: "s",
                          s: {
                            font: {
                              bold: true,
                              sz: 16,
                              color: { rgb: "0068af" },
                            },
                          },
                        },
                      ];
                      const data = hourLogs.map((item) => [
                        {
                          v: new Date(
                            formatDate(item.date)
                          ).toLocaleDateString(),
                        },
                        { v: Number(item.approvedHours).toFixed(1) },
                        { v: "0.0" },
                        { v: "0.0" },
                        { v: "0.0" },
                        { v: item.aheTicketCount },
                        { v: item.perTicketCount },
                        { v: item.hourLogCount },
                      ]);
                      exportToExcel(
                        [HEADINGS, ...data],
                        `${selectedUser?.label} Hour Log Report ${new Date(startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) + "-" + new Date(endDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`
                      );
                    }}
                  >
                    <BiBookAdd />
                    Create Hour Log Report
                  </Button>
                  <Button className="me-3" onClick={getHoursLogTicketReport}>
                    <BiBookAdd />
                    Create Tickets Report
                  </Button>
                  {/* <Button className="me-3" onClick={getHoursLogPreTicketReport}>
                    <BiBookAdd />
                    Create Pre-Tickets Report
                  </Button> */}
                  {hourLogs.length > 0 && (
                    <Button onClick={() => setPerTicketReportModalShow(true)}>
                      <BiBookAdd />
                      Per Ticket Report
                    </Button>
                  )}
                </Col>
              </Row>
            </Row>
          </Form>
          <Row>
            {isLoading ? (
              <p>Loading...</p>
            ) : hourLogs.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Total Hours</th>
                    <th>Hourlog Ticket Count</th>
                    <th>Per Ticket Count</th>
                    <th>AHE Ticket Count</th>
                    <th>Stat Holiday Hours</th>
                    <th>OT Total Hours</th>
                    <th>Reimbursement Count</th>
                    <th>Approved Hours</th>
                  </tr>
                </thead>
                <tbody>
                  {hourLogs.map((log, index) => (
                    <tr key={index}>
                      <td
                        className="link-primary"
                        role="button"
                        onClick={() => {
                          setLogDate(log.date);
                          setApproveDisapproveHourLogModalShow(true);
                        }}
                      >
                        {new Date(log.date).toLocaleDateString()}
                      </td>
                      <td>{log.totalHours}</td>
                      <td>{log.hourLogCount}</td>
                      <td>{log.perTicketCount}</td>
                      <td>{log.aheTicketCount}</td>
                      <td>0.0</td>
                      <td>0.0</td>
                      <td>{log.reimbursementCount}</td>
                      <td>{log.approvedHours}</td>
                    </tr>
                  ))}
                  {showTotal}
                </tbody>
              </table>
            ) : (
              <p>No hour log data available.</p>
            )}
          </Row>
          <Footer />
          <PayrollLogModal
            show={approveDisapproveHourLogModalShow}
            handleClose={handleClosePayrollLogModal}
            logDate={logDate}
            setLogDate={setLogDate}
            selectedUserId={selectedUser?.value}
          />
          <PerTicketReportModal
            show={perTicketReportModalShow}
            handleClose={() => setPerTicketReportModalShow(false)}
            logDate={formatDate(logDate)}
            startDate={startDate}
            endDate={endDate}
            selectedUser={selectedUser}
            formatDate={customFormatDate}
          />
        </Card>
      </section>
    </>
  );
};

export default Payroll;
