import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const addHourLog = async (data) => {
  console.log("DUCK", "addHoutLog");
  return await API.postMethod(ENDPOINT.hourlog.addHourLog, true, data);
};

const uploadReimbursement = async (data) => {
  console.log("DUCK", "uploadReimbursement");
  return await API.postMethod(ENDPOINT.hourlog.uploadReimbursement, true, data);
};

const getHourLog = async (fromDate, toDate,userId) => {
  console.log("DUCK", "getHourLog");
  return await API.getMethod(
    `${ENDPOINT.hourlog.getHourLog}?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}`,
    true
  );
};

const getQueryHourLog = async (data) => {
  console.log("DUCK", "getQueryHourLog");
  return await API.postMethod(
    `${ENDPOINT.hourlog.getQueryHourLog}`,
    true,
    data
  );
};

const getReimbursements = async (date,userId) => {
  console.log("DUCK", "getReimbursements");
  return await API.getMethod(
    `${ENDPOINT.hourlog.getReimbursements}?date=${date}&userId=${userId}`,
    true,
    false
  );
};

const deleteHourLog = async (data) => {
  console.log("DUCK", "deleteHourLog");
  return await API.deleteMethod(
    `${ENDPOINT.hourlog.deleteHourLog}`,
    true,
    data
  );
};

const updateHourLog = async (data) => {
  console.log("DUCK", "updateHourLog");
  return await API.patchMethod(`${ENDPOINT.hourlog.updateHourLog}`, true, data);
};

const getTicketSummary = async (date,userId) => {
  console.log("DUCK", "getTicketSummary");
  return await API.getMethod(
    `${ENDPOINT.hourlog.getTicketSummary}?date=${date}&userId=${userId}`,
    true,
    false
  );
};

const getPayrollReport = async (userId, fromDate, toDate) => {
  console.log("DUCK", "getPayrollReport");
  return await API.getMethod(
    `${ENDPOINT.hourlog.getPayrollReport}?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}`,
    true
  );
};

const getHoursLogTicketReport = async (userId, fromDate, toDate, reportGenerated) => {
  console.log("DUCK", "getHoursLogTicketReport");
  return await API.getMethod(
    `${ENDPOINT.hourlog.getHoursLogTicketReport}?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}&reportGenerated=${reportGenerated ? reportGenerated: false}`,
    true
  );
};

const getHoursLogPerTicketReport = async (userId, fromDate, toDate) => {
  console.log("DUCK", "getHoursLogPerTicketReport");
  return await API.getMethod(
    `${ENDPOINT.hourlog.getHoursLogPerTicketReport}?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}`,
    true
  );
};

const checkTicketStatus = async (userId,ticketNo) => {
  console.log("DUCK", "checkTicketStatus");
  return await API.getMethod(
    `${ENDPOINT.hourlog.checkTicketStatus}?userId=${userId}&ticketNo=${ticketNo}`,
    true,
    false
  );
};

const updateApproval = async (data) => {
  console.log("DUCK", "updateApproval");
  return await API.patchMethod(`${ENDPOINT.hourlog.updateApproval}`, true, data);
};

const getBillingReport = async ({fromDate,toDate,dateType,city,filterUnit}) => {
  console.log("DUCK", "getBillingReport");
  return await API.getMethod(
    `${ENDPOINT.hourlog.getBillingReport}?fromDate=${fromDate}&toDate=${toDate}&dateType=${dateType.value}&city=${city.value}&filterUnit=${filterUnit.value}`
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addHourLog,
  getHourLog,
  getQueryHourLog,
  getReimbursements,
  uploadReimbursement,
  deleteHourLog,
  updateHourLog,
  getTicketSummary,
  getPayrollReport,
  getHoursLogTicketReport,
  getHoursLogPerTicketReport,
  checkTicketStatus,
  updateApproval,
  getBillingReport
};
